import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AlertType } from '../data/data-types';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor() { }

  private _onLoadAnimation = new BehaviorSubject(true);
  private _alert = new BehaviorSubject<any>({
    isShowing:false,
  })

  onLoadAnimation$ = this._onLoadAnimation.asObservable();
  alert$ = this._alert.asObservable();

  runOnLoadAnimation(){
    this._onLoadAnimation.next(false);
    setTimeout(() => {
      this._onLoadAnimation.next(true);
    }, 2000);
  }

  setAlert(alertOptions:AlertType){
    this._alert.next(alertOptions);
  }
}

<pagination-template
  #p="paginationApi"
  (pageChange)="pageChange($event)"
  (pageBoundsCorrection)="pageChange($event)"
>
  <div
    class="d-flex justify-content-lg-between justify-content-center flex-column flex-lg-row"
  >
    <div class="d-flex lg:mb-0 mb-6">
      <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
        <a
          class="m-1 cursor-pointer text-decoration-none text-white px-3 py-2 rounded-3 bg-primary text-white"
          *ngIf="!p.isFirstPage()"
          (click)="p.previous()"
        >
          < Previous
        </a>
      </div>

      <div
        *ngFor="let page of p.pages"
        [class.current]="p.getCurrent() === page.value"
      >
        <a
          class="m-1 cursor-pointer text-decoration-none text-white px-3 py-2 rounded-3"
          (click)="p.setCurrent(page.value)"
          *ngIf="p.getCurrent() !== page.value"
        >
          <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span
            class="m-1 cursor-pointer px-3 py-2 rounded-3 bg-primary text-white"
            >{{ page.label }}</span
          >
        </div>
      </div>

      <div class="pagination-next" [class.disabled]="p.isLastPage()">
        <a
          *ngIf="!p.isLastPage()"
          class="m-1 px-3 py-2 cursor-pointer text-decoration-none text-white rounded-3 bg-primary text-white"
          (click)="p.next()"
        >
          Next >
        </a>
      </div>
    </div>

    <div class="font-bold text-white lg:ml-24 mb-6">
      <span class="">Results Per Page : </span>
      <select
        name=""
        class="border rounded-3 p-1 focus:outline-none focus:ring-blue-350"
        id=""
        (change)="itemsPerPage($event)"
      >
        <option
          *ngFor="let item of possible_items_per_page"
          [selected]="item === items_per_page"
          value="{{ item }}"
        >
          {{ item }}
        </option>
      </select>
    </div>
  </div>
</pagination-template>

import { Component, OnInit } from '@angular/core';
import { slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import { StateService } from 'src/app/services/state/state.service';
import {default as education} from '../../services/data/education';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
  animations: [
    slideInDownOnEnterAnimation(),
    slideOutDownOnLeaveAnimation()
  ]
})
export class EducationComponent implements OnInit {

  load:boolean;
  education = education;

  constructor(
    private state:StateService
  ) { }

  ngOnInit(): void {
    this.state.onLoadAnimation$.subscribe(
      res => this.load = res
    )
  }
}

import { StateService } from './../../services/state/state.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  url:string;
  constructor(
    public sidebarRouter: Router,
    private state:StateService) { }

  ngOnInit(): void {

  }

  changeRoute(route:string[]){
    if(this.sidebarRouter.url===route[0]){
      return;
    }
    this.state.runOnLoadAnimation()
    setTimeout(() => {
      this.sidebarRouter.navigate(route);
    }, 500);
    console.log(this.sidebarRouter.url)
  }

  rightRoute(route:string){
    if(this.sidebarRouter.url===route){
      return true;
    }else{
      return false;
    }
  }


}

import { Component, OnInit } from '@angular/core';
import { slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import { StateService } from 'src/app/services/state/state.service';
import {default as experience} from '../../services/data/experience';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
  animations: [
    slideInDownOnEnterAnimation(),
    slideOutDownOnLeaveAnimation()
  ]
})
export class ExperienceComponent implements OnInit {

  load:boolean;
  experience = experience;
  p=1;
  itemsPerPage = 3;

  constructor(
    private state:StateService
  ) { }

  ngOnInit(): void {
    this.state.onLoadAnimation$.subscribe(
      res => this.load = res
    )
  }

  changePage($event:any){
    this.p = $event;
    setTimeout(() => {
    }, 1000);
  }

  changeItemsPerPage($event:any){
    this.itemsPerPage = Number($event);
  }


}

<div class="z-50 absolute w-full pt-5" *ngIf="alertOptions.isShowing" >
  <div class="row justify-content-center">
        <div class="col-10 col-md-6">
          <div class="d-flex justify-content-center w-full">
            <div class="{{alertOptions.bgColor || 'bg-success'}} text-white text-center px-3 py-4 w-full" >
              {{alertOptions.message || 'This is a sample alert'}} <span (click)="closeAlert()" (mouseover)='closeButtonHover()'

              class="cursor-pointer float-right">X</span>
            </div>
          </div>
        </div>
  </div>

</div>

let projects = [
  {
    title:"Everykey Web Dashboard",
    url:'https://dashboard.everykey.com',
    status:'IN PROGRESS',
    image:'everykey_dashboard.png',
    role:'Full stack developer',
    description:`This is a customer facing dashboard for everykey customers. Its a portal that allows you to freeze or unfreeze your everykey
    (Special bluetooth key that stores users passwords),change your subscription plan,edit your personal details and
     verify your customer support tickets.Group and business features are currently being developed    `
  },
  {
    title:'My Ladder Africa',
    url:'https://myladder.africa/',
    status: 'COMPLETED',
    image:'ladder_africa.png',
    role:'Frontend Developer',
    description: `This is a customer facing website for Ladder.
   I am currently creating the an onboarding flow which connects users to the mobile app for this website.
    `
  },
  {
    title:'My Ladder Admin Dashboard',
    url:'',
    status: 'COMPLETED',
    image:'ladder_dashboard_1.png',
    role:'Frontend Developer',
    description: `This is an admin dashboard for the My Ladder application.
    It is a single page application that allows the user to view and edit the data in the database.
     It was built using Next JS
    `
  },
  {
    title:'Savings Application',
    url:'',
    status:'ARCHIVED',
    image:'saveme_ui.png',
    role:'Full Stack Developer',
    description:`This is the latest version of a finance app I began building for
    my final year capstone project. It was built to aid SMEs in bookkeeping and tracking their income
    and their expenditure. It was initially built using React+Express(NodeJS)+SQL.
    But was redesigned by Carl Imoru Aryana
    and is being built using Angular, Bootstrap, Laravel (PhP) and SQL.
    `
  },
  {
    title:'Asedas Finds',
    url:'https://github.com/KwakuBoohene/asedas_finds',
    image:'asedas_finds_1.png',
    status:'IN PROGRESS',
    role:'Full Stack Developer',
    description:`The Asedas Find website is an ecommerce store for
     the Asedas Find brand, to simplify and digitize the buying and selling of clothes online.
     The user interface was designed by Carl Imoru Ayarna and I built both the Front End
    and the Back End Layers`
  },

  {
    title:'Ashesi Hive Online',
    url:'',
    status: 'ARCHIVED',
    image:'asc_1.png',
    role:'Backend Developer',
    description: `This is a website built for the Ashesi University Student Council to broadcast announcements
    and events to the general student body. It contains useful information which aids student
    life in the Ashesi University Community.
    It has a management dashboard which can be used to manage and update information
    on the website.
     I aided in the developement of the backend layer (using Laravel) of the website and worked with BR_DGE
     (a Ghanaian tech startup) to build this website
    `
  },

];

export default projects;

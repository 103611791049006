<div class="sidebar">
  <div class="sidebar-header text-center">
    <p class="d-flex justify-content-center">
      <span class="circle">
        <img
          src="../../../assets/img/profile_pic.png"
          class="img-fluid"
          alt=""
        />
      </span>
    </p>
  </div>

  <div class="sidebar-body">
    <div
      (click)="changeRoute(['/'])"
      [ngClass]="{ 'router-link-active': rightRoute('/') }"
      class="sidebar-item"
    >
      <span class="icon"> </span>
      <span class="title-holder">
        <span class="title">ABOUT</span>
      </span>
    </div>

    <div
      (click)="changeRoute(['/experience'])"
      [ngClass]="{ 'router-link-active': rightRoute('/experience') }"
      class="sidebar-item"
    >
      <span class="icon"> </span>
      <span class="title-holder">
        <span class="title">EXPERIENCE</span>
      </span>
    </div>

    <div
      (click)="changeRoute(['/projects'])"
      [ngClass]="{ 'router-link-active': rightRoute('/projects') }"
      class="sidebar-item"
    >
      <span class="icon"> </span>
      <span class="title-holder">
        <span class="title">PROJECTS</span>
      </span>
    </div>

    <div
      (click)="changeRoute(['/education'])"
      [ngClass]="{ 'router-link-active': rightRoute('/education') }"
      class="sidebar-item"
    >
      <span class="icon"> </span>
      <span class="title-holder">
        <span class="title">EDUCATION</span>
      </span>
    </div>

    <div
      (click)="changeRoute(['/skills'])"
      [ngClass]="{ 'router-link-active': rightRoute('/skills') }"
      class="sidebar-item"
    >
      <span class="icon"> </span>
      <span class="title-holder">
        <span class="title">SKILLS</span>
      </span>
    </div>

    <div
      (click)="changeRoute(['/contact'])"
      [ngClass]="{ 'router-link-active': rightRoute('/contact') }"
      class="sidebar-item"
    >
      <span class="icon"> </span>
      <span class="title-holder">
        <span class="title">CONTACT</span>
      </span>
    </div>

    <!-- <div
      (click)="changeRoute(['/pay-me'])"
      [ngClass]="{ 'router-link-active': rightRoute('/pay-me') }"
      class="sidebar-item"
    >
      <span class="icon"> </span>
      <span class="title-holder">
        <span class="title">PAY ME</span>
      </span>
    </div> -->
  </div>
</div>

import { StateService } from 'src/app/services/state/state.service';
import { Component, OnInit } from '@angular/core';
import { slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
  animations: [
    slideInDownOnEnterAnimation(),
    slideOutDownOnLeaveAnimation()
  ]
})
export class SkillsComponent implements OnInit {

  load:boolean;
  constructor(
    private state:StateService
  ) { }

  ngOnInit(): void {
    this.state.onLoadAnimation$.subscribe(
      res => this.load = res
    )
  }

}

<div class="row justify-content-center text-white fade-in">
  <div class="col-lg-5 col-10">
    <p class="text-center">PAY ME HERE</p>

    <div class="mt-5 w-full">
      <form [formGroup]="donateForm" action="" class="w-full">
        <div class="form-group">
          <label for="">Email</label>
          <input
            type="email"
            formControlName="email"
            name=""
            id=""
            class="form-control"
          />
          <div *ngIf="donateForm.controls.email.errors" class="">
            <small
              *ngIf="donateForm.controls.email.errors.required"
              class="text-danger"
              >{{ donateForm.controls.email.errors.required.message }}
            </small>
            <small
              *ngIf="donateForm.controls.email.errors.email"
              class="text-danger"
              >{{ donateForm.controls.email.errors.email.message }}
            </small>
          </div>
        </div>

        <div class="form-group">
          <label for="">Amount</label>
          <input
            formControlName="amount"
            type="text"
            name=""
            id=""
            class="form-control"
          />
          <div *ngIf="donateForm.controls.amount.errors" class="">
            <small
              *ngIf="donateForm.controls.amount.errors.required"
              class="text-danger"
              >{{ donateForm.controls.amount.errors.required.message }}
            </small>
            <small
              *ngIf="donateForm.controls.amount.errors.numeric"
              class="text-danger"
              >{{ donateForm.controls.amount.errors.numeric.message }}
            </small>
          </div>
        </div>

        <div class="form-button my-3">
          <button
            #paystack
            angular4-paystack
            class="d-none"
            [key]="key"
            [email]="donateForm.controls.email.value"
            [amount]="convertToNumber(donateForm.controls.amount.value)"
            [ref]="ref"
            [currency]="'GHS'"
            (paymentInit)="paymentInit()"
            (onClose)="paymentCancel()"
            (callback)="paymentDone($event)"
          >
            Pay with Paystack
          </button>

          <button
            (click)="PayWith(paystack)"
            class="btn btn-primary text-white"
          >
            Pay with Paystack
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

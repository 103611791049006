<div class="no__scrollbar fade-in">
  <div class="row justify-content-center mt-3 no__scrollbar">
    <div class="col-xl-12 no__scrollbar">
      <p class="h2 text-white">PROJECTS</p>

      <div class="row">
        <div
          *ngFor="
            let item of projects
              | paginate: { itemsPerPage: itemsPerPage, currentPage: p }
          "
          class="col-xl-12 no__scrollbar"
        >
          <div class="m-1 mb-3">
            <div class="project text-white">
              <div class="description row">
                <div class="col-lg-3 col-sm-12">
                  <a
                    href="{{ item.url }}"
                    class="text-primary pb-1 text-decoration-none fw-bold fs-5"
                    >{{ item.title }}</a
                  >
                  <img
                    class="img-fluid"
                    src="../../../assets/img/{{ item.image }}"
                    alt=""
                  />
                </div>
                <div class="col-lg-2 col-sm-12">
                  <p class="text-center fw-bold">
                    {{ item.status }}
                  </p>
                </div>
                <div class="col-lg-6 offset-lg-1 col-sm-12">
                  <p class="">
                    {{ item.role }}
                  </p>
                  <p class="">
                    {{ item.description | shorten: 200 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-custom-pagination
        [p]="p"
        [items_per_page]="itemsPerPage"
        (changePage)="changePage($event)"
        (changeItemsPerPage)="changeItemsPerPage($event)"
      >
      </app-custom-pagination>
    </div>
  </div>
</div>

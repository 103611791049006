<div class="layout-body">
  <div class="pt-5 main overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-xl-11 row">
              <div
                class="col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block hidden"
              >
                <app-sidebar></app-sidebar>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12 col-12 body">
                <app-mobile-header></app-mobile-header>
                <ng-content></ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit {

  @Input() p: number;
  @Input() items_per_page:number
  @Output() changePage = new EventEmitter<any>();
  @Output() changeItemsPerPage = new EventEmitter<number>();
  possible_items_per_page = [3,5,10,15]

  constructor() { }

  ngOnInit(): void {
  }

  pageChange($event:any){
    this.changePage.emit($event)
  }

  itemsPerPage($event:any){
    this.changeItemsPerPage.emit($event.target.value);
  }

}

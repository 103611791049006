import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { PaystackOptions } from 'angular4-paystack';
import { StateService } from 'src/app/services/state/state.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {
  title;

  donateForm:FormGroup = new FormGroup({
    email: new FormControl('',[RxwebValidators.required(
      {message:'Please enter an email'}),
      RxwebValidators.email(
        {message:'Please enter a valid email'})
      ]),
    amount: new FormControl('',[RxwebValidators.required(
      {message:'Please enter an amount'}),
      RxwebValidators.numeric({message:'Please enter a valid amount'})
    ]),
  })

  email:string;amount:number;key:string;ref:string;
  constructor(private state:StateService) {}

  ngOnInit(): void {
    this.email = this.donateForm.controls.email.value;
    this.amount = Number(this.donateForm.controls.amount.value)*100;
    this.key =environment.key;
    this.ref = uuidv4();
  }

  paymentInit() {

  }

  paymentDone(ref: any) {
    this.state.setAlert({
      isShowing:true,
      bgColor:'bg-success',
      message:'Payment Successful',
    })
    this.donateForm.reset();
  }

  PayWith(paystackButton:HTMLElement) {
    console.log(this.donateForm.controls.email.errors);
    console.log(this.donateForm.controls.amount.errors);
    if (this.donateForm.valid) {
      paystackButton.click();
    }else{
      this.paymentCancel();
    }

  }

  convertToNumber(value:string):number{
    return Number(value)*100;
  }

  paymentCancel() {
    this.state.setAlert({
      isShowing:true,
      bgColor:'bg-danger',
      message:'Payment Failed',
    })
  }

  createUUID():string{
    return uuidv4();
  }

}

import { StateService } from './services/state/state.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-website';

  constructor(
    private stateService: StateService){

    }

  changeOfRoutes():void{
    
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {

  url:string;
  constructor(
    public sidebarRouter: Router,
    private state:StateService) { }

  ngOnInit(): void {

  }

  changeRoute(route:string[]){
    if(this.sidebarRouter.url===route[0]){
      return;
    }
    this.state.runOnLoadAnimation()
    setTimeout(() => {
      this.sidebarRouter.navigate(route);
    }, 500);
  }

  rightRoute(route:string){
    if(this.sidebarRouter.url===route){
      return true;
    }else{
      return false;
    }
  }

}

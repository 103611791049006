let experience =
[
  {
    company: 'Everykey Inc',
    url:'https://www.everykey.com/',
    position:[
      {
        title: 'Full Stack Engineer',
        duration: 'June 2022 till present',
        location:"Cleveland , Ohio , USA (Remote)",
        type:'Contract'
      }
    ],
  },

   {
    company: 'Ladder',
    url:'https://www.myladder.africa/',
    position:[
      {
        title: 'Frontend Engineer',
        duration: 'March 2022 - January 2023',
        location: "Accra,Greater Accra, Ghana",
        type:'Full Time'
      }
    ]
  },


  {
    company: 'SaharaGO LLC.',
    url:'https://www.saharago.com',
    position:[
      {
        title: 'Software Engineer I',
        duration: 'December 2021 - June 2022',
        location: "Accra,Greater Accra, Ghana",
        type:'Contract'
      }
    ]
  },

  {
    company: 'interVAS Limited',
    url:'https://www.intervas.com/',
    position:[
      {
        title: 'Web Developer',
        duration: 'August 2020 - March 2022',
        location: "Accra,Greater Accra, Ghana",
        type:'Full Time'
      }
    ]
  },
  {
    company: 'Ashesi University',
    url:'https://www.ashesi.edu.gh/',
    position:[
      {
        title: 'Faculty Intern for CSIS Department',
        duration: 'August 2020 - July 2021',
        location: "Berekuso, Eastern Region, Ghana",

      }
    ]
  },
  {
    company: 'Sylgam Travel and Tours',
    url:undefined,
    position:[
      {
        title: 'Social Media Marketing and Web Development Intern',
        duration: 'September 2018 - November 2018',
        location: "",
        type:'Internship'
      }
    ]
  },
  {
    company: 'Akatuapa Limited (Formerly PsypherTxt)',
    url:'http://akatuapa.com/',
    position:[
      {
        title: 'Software Developer (Front End)',
        duration: 'June 2018 - July 2018',
        location: "",
        type:'Internship'
      }
    ]
  }
];

export default experience;

<div class="container fade-in">
  <div class="row justify-content-center mt-3">
    <div class="col-xl-10 col-lg-10 col-md-7">
      <div class="top-half d-flex align-items-end">
        <span class="text-center text-white fw-bold fs-4">
          HI MY NAME IS <br />
          <span class="fs-1 text-secondary"> KWAKU BOOHENE </span> <br />
          AND I AM A <br />
          <span class="fs-1 text-secondary"> SOFTWARE ENGINEER </span>
        </span>
      </div>
    </div>

    <div class="col-xl-10 col-lg-10 col-md-7 col-sm-12">
      <div class="d-flex bottom-half pb-3 align-items-end">
        <span class="text-white fs-5 fw-bold">
          CONTACT ME ON MY SOCIALS
          <a
            href="http://www.twitter.com/KwakuBoohene"
            class="text-primary ps-4"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/kwakuboohene/"
            class="text-primary ps-4"
          >
            <i class="fab fa-linkedin"></i>
          </a>
          <a href="https://github.com/KwakuBoohene" class="text-primary ps-4">
            <i class="fab fa-github"></i>
          </a>
        </span>
      </div>
    </div>
  </div>
</div>

import { StateService } from './../../services/state/state.service';
import { Component, OnInit } from '@angular/core';
import { slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    slideInDownOnEnterAnimation(),
    slideOutDownOnLeaveAnimation()
  ]
})
export class AboutComponent implements OnInit {

  load:boolean= true;

  constructor(
    private state:StateService
  ) { }

  ngOnInit(): void {
    this.state.onLoadAnimation$.subscribe(
      res => this.load = res
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    slideInDownOnEnterAnimation(),
    slideOutDownOnLeaveAnimation()
  ]
})
export class ContactComponent implements OnInit {

  load:boolean= true;

  constructor(
    private state:StateService
  ) { }

  ngOnInit(): void {
    this.state.onLoadAnimation$.subscribe(
      res => this.load = res
    )
  }

}

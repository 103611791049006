let education = [
  {
    school: 'Ashesi University',
    location:'Berekuso , Eastern Region, Ghana',
    course: 'BSc. Management Information Systems',
    duration: 'August 2016 - May 2020',
    url:'https://www.ashesi.edu.gh/'
  },
  {
    school: 'Achimota School',
    location:'Achimota, Greater Accra,Ghana',
    course: 'General Science (with Geography)',
    duration: 'November 2013 - May 2016',
    url:'https://oldachimotan.org/contact/'
  },
];

export default education;

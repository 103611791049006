import { Component, OnInit } from '@angular/core';
import {AlertType} from '../../services/data/data-types';
import {StateService} from '../../services/state/state.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  alertOptions:AlertType;
  onCloseButton:boolean;
  constructor(
    private state:StateService
  ) { }

  ngOnInit(): void {
    this.state.alert$.subscribe(
      res => {
        this.alertOptions = res;
        if(res.isShowing===true){
          setTimeout(() => {
            this.closeAlert();
          }, 2000);
        }
      }
    )
  }

  closeAlert(){
    this.state.setAlert({
      isShowing:false
    })
  }

  closeButtonHover(){
    this.onCloseButton = true;
    setTimeout(() => {
      this.onCloseButton = false;
    }, 1000);
  }

}

<div class="container">
  <div class="row justify-content-center mt-3">
    <div class="col-xl-10">
      <div class="top-half text-center text-white">
        <div class="">
          <span class="fw-bold fs-4 pe-3">SEND ME AN EMAIL :</span>
          <span class="fs-4 text-primary">KWAKU.KWAYISI@GMAIL.COM</span>
          <br /><br /><br />

          <span class="fw-bold fs-2">CONNECT WITH ME ON :</span>
          <br /><br /><br />
          <a
            href="http://www.twitter.com/KwakuBoohene"
            class="fw-bold pe-3 text-decoration-none text-secondary fs-4"
          >
            TWITTER <i class="fab fa-twitter"></i> :
          </a>
          <span class="fs-4 text-primary"> @KWAKUBOOHENE </span>
          <br /><br /><br />
          <a
            href="https://www.linkedin.com/in/kwakuboohene/"
            class="fw-bold text-decoration-none text-secondary pe-3 fs-4"
          >
            LINKEDIN <i class="fab fa-linkedin"></i>:</a
          >
          <span class="fs-4 text-primary">KWAKU BOOHENE</span>
          <br /><br /><br />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container fade-in">
  <div class="row justify-content-center mt-3">
    <div class="col-xl-12">
      <p class="h2 text-white">SKILLS & KNOWLEDGE AREAS</p>

      <div class="row">
        <div class="col-xl-12">
          <div class="skill">
            <div class="skill-heading">
              <span class="">WEB DEVELOPMENT</span>
            </div>

            <div class="tools row">
              <div class="col-lg-3 col-sm-6">
                <span class="tool">Angular (TypeScript) </span>
                <span class="tool">Laravel (PhP) </span>
                <span class="tool">ReactJS </span>
              </div>
              <div class="col-lg-3 col-sm-6">
                <span class="tool">NodeJS </span>
                <span class="tool">Bootstrap </span>
                <span class="tool">Wordpress </span>
              </div>
              <div class="col-lg-3 col-sm-6">
                <span class="tool">HTML </span>
                <span class="tool">CSS </span>
                <span class="tool">JavaScript </span>
              </div>
             
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-6">
               
                <span class="tool">Google Cloud </span>
                <span class="tool">Heroku Deployment </span>
              </div>
              <div class="col-lg-9 col-sm-12">
                <span class="tool"
                >Amazon Web Services (EC2,Cognito, RDS, IAM,Route 53, Elastic
                Load Balancing, Certificate manager, Amplify)
              </span>
              </div>
             
            </div>
          </div>

          <div class="skill">
            <div class="skill-heading">
              <span class="">DATA STRUCTURES AND ALGORITHMS</span>
            </div>

            <div class="tools row">
              <span class="tool">Python </span>
            </div>
          </div>

          <div class="skill">
            <div class="skill-heading">
              <span class="">ADDITIONAL TOOLS I HAVE USED</span>
            </div>

            <div class="tools row">
              <span class="tool">Java </span>
              <span class="tool">Django</span>
              <span class="tool">Selenium</span>
              <span class="tool">Flask</span>
            </div>
          </div>

          <div class="learning">
            <div class="skill-heading">
              <span class="">THINGS IM CURRENTLY LEARNING</span>
            </div>

            <div class="tools row">
              <span class="tool">Statistics and Mathematics For Machine Learning </span>
              <span class="tool">Terraform</span>
              <span class="tool">Web Scrapping</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="header-mobile ps-3 d-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none">
  <div class="header-nav text-white">
    <div (click)="changeRoute(['/'])"
    [ngClass]="{'router-link-active': rightRoute('/')}"
    class="nav-item">
      <span class="title-holder">
        <span class="title">ABOUT</span>
      </span>

    </div>
    <div (click)="changeRoute(['/experience'])"
    [ngClass]="{'router-link-active': rightRoute('/experience')}"
    class="nav-item">
      <span class="title-holder">
        <span class="title">EXPERIENCE</span>
      </span>
    </div>
    <div (click)="changeRoute(['/projects'])"
    [ngClass]="{'router-link-active': rightRoute('/projects')}"
    class="nav-item">
      <span class="title-holder">
        <span class="title">PROJECTS</span>
      </span>
    </div>
    <div (click)="changeRoute(['/education'])"
    [ngClass]="{'router-link-active': rightRoute('/education')}"
    class="nav-item">
      <span class="title-holder">
        <span class="title">EDUCATION</span>
      </span>
    </div>
    <div (click)="changeRoute(['/skills'])"
    [ngClass]="{'router-link-active': rightRoute('/skills')}"
    class="nav-item">
      <span class="title-holder">
        <span class="title">SKILLS & KNOWLEDGE</span>
      </span>
    </div>
    <div (click)="changeRoute(['/contact'])"
    [ngClass]="{'router-link-active': rightRoute('/contact')}"
    class="nav-item">
      <span class="title-holder">
        <span class="title">CONTACT</span>
      </span>
    </div>

    <div (click)="changeRoute(['/pay-me'])"
    [ngClass]="{'router-link-active': rightRoute('/pay-me')}"
    class="nav-item">
      <span class="title-holder">
        <span class="title">PAY ME</span>
      </span>
    </div>

  </div>
</div>

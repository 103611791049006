<div class="container fade-in">
  <div class="row justify-content-center mt-3">
    <div class="col-xl-12">
      <div
        *ngFor="
          let item of experience
            | paginate: { itemsPerPage: itemsPerPage, currentPage: p }
        "
        class="experience text-white mb-4"
      >
        <div class="company">
          <a href="{{ item.url }}" class="text-primary fw-bold fs-2">
            {{ item.company }}
          </a>
        </div>

        <div *ngFor="let position of item.position" class="position row">
          <div class="col-lg-7 col-sm-12">
            <span class="fw-bold fs-4 text-white">
              {{ position.title }}
            </span>
            <br>
            <span class="text-white">{{position.location}}</span>
            <br>
            <span class="text-white">{{position.type}}</span>
          </div>
          <div class="col-lg-4 col-sm-12 offset-lg-1">
            <span class="">
              {{ position.duration }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-custom-pagination
    [p]="p"
    [items_per_page]="itemsPerPage"
    (changePage)="changePage($event)"
    (changeItemsPerPage)="changeItemsPerPage($event)"
  >
  </app-custom-pagination>
</div>

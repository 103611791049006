<div class="container fade-in">
  <div class="row justify-content-center mt-3">
    <div class="col-xl-12">
      <div *ngFor="let item of education" class="education text-white mb-4">
        <div class="education">
          <a
            href="{{ item.url }}"
            class="text-primary text-decoration-none fw-bold fs-2"
          >
            {{ item.school }}
          </a>
        </div>

        <div class="position row">
          <div class="col-lg-7 col-sm-12">
            <span class="fw-bold fs-4 text-white">
              {{ item.course }}
            </span>
          </div>
          <div class="col-lg-4 offset-lg-1 col-sm-12">
            <span class="">
              {{ item.duration }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

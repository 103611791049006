import { Component, OnInit } from '@angular/core';
import { slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import { StateService } from 'src/app/services/state/state.service';
import {default as projects} from 'src/app/services/data/projects';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  animations: [
    slideInDownOnEnterAnimation(),
    slideOutDownOnLeaveAnimation()
  ]
})
export class ProjectComponent implements OnInit {
  p=1;
  itemsPerPage = 3;
  projects = projects;
  load:boolean;
  constructor(
    private state:StateService
  ) { }

  ngOnInit(): void {
    console.log(this.projects);
    this.state.onLoadAnimation$.subscribe(
      res => this.load = res
    )
  }

  changePage($event:any){
    this.p = $event;
    setTimeout(() => {
    }, 1000);
  }

  changeItemsPerPage($event:any){
    this.itemsPerPage = Number($event);
  }

}
